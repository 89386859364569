<template>
  <div>
    <b-row class="mb-2">
      <b-col
        col
        lg="6"
      >
        <h4> Gerenciar Grupo </h4>
        <small v-if="itemData.nome">Grupo: {{ itemData.nome }}</small>
        <small v-else>Novo Grupo</small>
      </b-col>

    </b-row>
    <hr>
    <b-row>
      <b-col cols="12">
        <b-form-group
          label="Nome do Grupo"
          label-for="nome-grupo"
        >
          <b-form-input
            v-model="itemData.nome"
            placeholder="Nome do Grupo"
            label="Nome do Grupo"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-form-group
          label="Tarefas do grupo"
          label-for="tarefas-grupo"
        >
          <b-form-checkbox-group
            v-model="tarefasSelecionadas"
            :options="listaTarefas"
            value-field="uuid"
            text-field="descricao"
            class="listaTarefas"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <!-- Action Buttons -->
    <b-col class="text-right pr-0">
      <b-button
        variant="outline-secondary"
        type="button"
        @click="goBack"
      >
        Voltar
      </b-button>
      <b-button
        type="button"
        variant="success"
        class="mb-1 mb-sm-0 mr-0 ml-sm-1"
        @click="editarItem(itemData)"
      >
        Salvar
      </b-button>
    </b-col>
  </div>
</template>

<script>
import {
  BButton, BCol, BFormCheckboxGroup, BFormGroup, BFormInput, BRow,
} from 'bootstrap-vue'
import { computed, ref, watch } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import router from '@/router'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {

  components: {
    BFormCheckboxGroup,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
  },
  props: {
    itemData: {
      type: Object,
      required: false,
    },

  },
  setup(props) {
    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)
    const tarefasSelecionadas = ref([])

    if (router.currentRoute.params.id !== 'novo') {
      watch(() => props.itemData.tarefas, newTarefas => {
        tarefasSelecionadas.value = newTarefas ? newTarefas.map(tarefa => tarefa.uuid) : []
      }, { immediate: true })
    }

    const listaTarefas = computed(() => store.state['app-grupo-favoritos'].tarefasDaSub)

    // Use toast
    const toast = useToast()
    const goBack = () => {
      router.go(-1)
    }

    const editarItem = item => {
      const dadosItem = {
        ...item,
        tarefas: tarefasSelecionadas.value,
        idSub: store.state['app-grupo-favoritos'].idSub,
      }

      if (router.currentRoute.params.id !== 'novo') {
        store.dispatch('app-grupo-favoritos/update', dadosItem)
          .then(respo => {
            goBack()
            toast({
              component: ToastificationContent,
              props: {
                title: 'Grupo editado com sucesso',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
          })
      } else {
        store.dispatch('app-grupo-favoritos/store', dadosItem)
          .then(respo => {
            goBack()
            toast({
              component: ToastificationContent,
              props: {
                title: 'Grupo adicionado com sucesso',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
          })
      }
    }

    return {
      refInputEl,
      previewEl,
      editarItem,
      tarefasSelecionadas,
      listaTarefas,
      goBack,
    }
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.listaTarefas {
  margin: 16px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;

  .custom-control-label {
    font-size: 0.8em;
  }
}

.list-group-item {
  transition: all 1s;
  cursor: move;
}

</style>
